<template>
  <v-container>
    <v-card
      max-width="360"
      class="mx-auto my-12 grey lighten-3"
    >
      <v-card-text class="px-8 py-4">
        <div class="logo">
          <img src="../assets/vajper-logo-final.png" />
        </div>
        <v-text-field
          v-model="formState.username"
          label="Användarnamn"
          required
          background-color="white"
          outlined
          prepend-inner-icon="mdi-account-circle-outline"
        ></v-text-field>
        <v-text-field
          v-model="formState.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="passwordType"
          :label="passwordLabel"
          required
          background-color="white"
          @keydown.enter="doLogin()"
          :disable="passwordDisable"
          outlined
          @click:append="showPassword = !showPassword"
          prepend-inner-icon="mdi-lock-outline"
        ></v-text-field>
        <!--
        <button type="button" @click="switchLanguage()">{{ t('Byt till svenska') }}</button>
        -->
      </v-card-text>
      <v-card-actions
        class="grey darken-3"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          text
          @click="doLogin()"
          :disabled="loginButtonDisabled"
        >
          <!-- {{ t('Sign in') }} -->
          Logga in
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
  </v-container>
</template>

<script>
import SnackBar from '../components/SnackBar'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import useCore from '../helpers/core'
// import { useI18n } from 'vue-i18n'
// import { useStore } from 'vuex'

export default {
  name: 'Login',
  components: {
   'app-snack-bar': SnackBar,
  },
  setup() {
    // const store = useStore();

    // const { t, locale } = useI18n({
    //   messages: {
    //     sv: {
    //       'Username': 'Användarnamn',
    //       'Password': 'Lösenord',
    //       'Try again in': 'Försök igen om',
    //       'Sign in': 'Logga in',
    //       'Byt till svenska': 'In english',
    //     },
    //   }
    // });

    const { login, logToConsole } = useCore();

    //formState
    const formState = reactive({
      username: '',
      password: '',
    });

    const showPassword = ref(false);
    const nextLoginAttemptSeconds = ref(0);

    const passwordType = computed(() => {
      return showPassword.value || isAwaitingNewLoginAttempt.value ? 'text' : 'password';
    });

    const isAwaitingNewLoginAttempt = computed(() => {
      return nextLoginAttemptSeconds.value !== 0;
    });

    const passwordLabel = computed(() => {
      return isAwaitingNewLoginAttempt.value ? 'Försök igen om ' + nextLoginAttemptSeconds.value + ' s' : 'Lösenord';
    });

    const passwordDisable = computed(() => {
      return isAwaitingNewLoginAttempt.value;
    });

    const showSeconds = computed(() => {
      return isAwaitingNewLoginAttempt.value;
    });

    const loginButtonDisabled = computed(() => {
      return isAwaitingNewLoginAttempt.value;
    });

    const passwordInput = ref('');

    watch(() => nextLoginAttemptSeconds.value, () => {
      const anySeconds = nextLoginAttemptSeconds.value !== 0;
      formState.password = anySeconds ? '' : passwordInput.value;
    });

    const doLogin = async () => {
      const username = formState.username.trim();
      const password = formState.password.trim();
      passwordInput.value = password;
      if (username.length === 0 || password.length === 0) {
        console.log('blank values');
        return;
      }
      const apiResponse = await login(username, password, 'sv');
      logToConsole('apiResponse', apiResponse);
      if (apiResponse && apiResponse.seconds) {
        //start timer for next login attempt
        nextLoginAttemptSeconds.value = apiResponse.seconds;
        initNextLoginAttemptCounter();
      }
    }

    const switchLanguage = () => {
      // locale.value = (locale.value === 'sv') ? 'en' : 'sv';
      // this.$store.dispatch('switchLanguage', locale.value);
    }

    const initNextLoginAttemptCounter = () => {
      // console.log('initNextLoginAttemptCounter', nextLoginAttemptSeconds.value);
      setTimeout(() => {
        nextLoginAttemptSeconds.value--;
        if (nextLoginAttemptSeconds.value > 0) {
          initNextLoginAttemptCounter();
        }
      }, 1000);
    }

    return {
      formState,
      loginButtonDisabled,
      nextLoginAttemptSeconds,
      doLogin,
      showSeconds,
      // t,
      switchLanguage,
      passwordLabel,
      passwordDisable,
      passwordType,
      showPassword,
    }
  }
}
</script>

<style scoped>
  .logo > img {
    width: 180px;
  }
  .logo {
    text-align:center;
    padding: 20px 40px;
  }
</style>